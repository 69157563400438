/* Default (Desktop and Tablet) */
.instructor-card {
  background: linear-gradient(180deg, #937d51 0%, #62370f 100%);
  color: white;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 30px;
}

.instructor-logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.trainer-image {
  width: 450px;
  height: 70px;
}

.designed-by-card {
  background: linear-gradient(180deg, #3b2c10 50%, #000000 100%);
  color: white;
  padding: 20px;
  border-radius: 16px;
  margin-top: 30px;
  border: 1px solid #71613f;
}

.designed-by-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.designed-by-logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.DesignedByCSS {
  position: absolute;
  top: 327px;
  right: auto;
  padding: 12px 20px;
  border-radius: 10px;
  color: black;
  transform: rotate(0deg);
  white-space: nowrap;
  overflow: visible;
  font-size: 13px;
}
.designerNames {
  text-align: center;
  display: block; /* Default block display */
}
.companyNames {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  border-radius: 8px;
  width: 14%;
  height: 45px;
  margin: 10px;
  border: 1px solid #94969c;
  box-sizing: border-box;
}
.CompayNamesMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3;
  row-gap: 2;
  width: 75%;
  align-items: center;
  margin-left: 120px;
}

.applicationBox {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.applyButtonText {
  font-size: 12px;
  margin-top: 20px;
}

/* Mobile View (screen width below 600px) */
@media only screen and (max-width: 600px) {
  .DesignedByCSS {
    top: auto;
    width: 160px;
    text-align: center;
  }
  .designerNames {
    display: inline;
    text-align: center;
    font-size: 11px;
  }

  .CompayNamesMain {
    margin-left: 0px;
    width: 100%;
  }
  .companyNames {
    width: 26% !important;
  }
  .applicationBox {
    display: block;
  }
  .applyButtonText {
    display: none;
  }
}

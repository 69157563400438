.container {
  --color: #239ce2;
  min-width: 700px;
  max-width: 850px;
  margin: 2px;
  flex: 1.2;
  height: fit-content;
  min-height: 900px;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
}

.header {
  display: flex;
  flex-direction: column;
}

.header .heading {
  font-size: 2.8rem;
  font-weight: 600;
  text-transform: capitalize;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #383737;
}

.header .subHeading {
  color: var(--color);
  font-weight: 500;
  font-size: 1.1rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
}

.header .links {
  margin-top: 15px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  row-gap: 10px;
  font-weight: 600;
}

.header .link {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 600;
}

.header .link svg {
  color: var(--color);
  height: 14px;
  width: 14px;
}

.main {
  display: flex;
  gap: 30px;
}

.col1 {
  flex: 1.3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.col2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section .sectionTitle {
  font-size: 1.1rem;
  font-weight: 600;
  width: 80%;
  padding-bottom: 3px;
  border-bottom: 1px solid #585555;
}

.section .content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}
.section .content .item {
  border-bottom: 1px dotted lightgray;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 10px;
}
.section .content .item:last-child {
  border-bottom: none;
}

.content .title {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3rem;
}

.content .subTitle {
  font-weight: bold;
  color: var(--color);
  font-size: 13px;
}

.content .overview {
  font-size: 13px;
}

.content .link {
  display: flex;
  gap: 5px;
  font-size: 0.75rem;
  cursor: pointer;
  color: var(--color);
  font-weight: 600;
}
.content .link svg {
  height: 14px;
  width: 14px;
  color: var(--color);
}

.content .date {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
}

.content .date svg {
  height: 14px;
  width: 14px;
}

.content .points,
.numbered {
  padding-left: 0px;
  font-size: 13px;
  list-style: disc;
  list-style-position: outside;
  margin-left: 14px;
}

.numbered li {
  list-style-type: decimal;
}

.hidden {
  display: none;
}
@media only screen and (max-width: 768px) {
  .container {
    min-width: auto;
    padding: 2px;
    display: none;
  }
}
